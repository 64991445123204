<template lang="html">

  <section class="test">
    <img src="/img/test.webp" />
  </section>

</template>

<script lang="js">

  export default  {
    name: 'test',
    props: [],
    mounted () {

    },
    data () {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
}


</script>

<style scoped lang="scss">
  .test {

  }
</style>
